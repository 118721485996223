/* eslint-disable react/prop-types */
import React, {useContext, useState} from "react";
import {Button, OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import {AddBox, IndeterminateCheckBox} from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import {makeStyles} from "@material-ui/core";
import {DeleteModalContext} from "../../../globalCompo/DeleteModal";
import criteriaApi from "../../../api/criteriaApi";
import CriterionOrderModal from "./CriterionOrderModal";
import UpdateCriterionModal from "./UpdateCriterionModal";

export default function Criteria ({category, getCategory}) {
  const classes = useStyle()
  const deleteModalContext = useContext(DeleteModalContext)
  const [modal, setModal] = useState({show: false, criterion: undefined})
  const [criterionOrderModal, setCriterionOrderModal] = useState({show: false, criterion: undefined})

  const getCategoryCriteria = category => category?.criteria ?? []
  const hideUpdateCriterionModal = () => setModal({show: false, criterion: undefined});
  const hideCriterionOrderModal = () => setCriterionOrderModal({show: false, criterion: undefined});
  const addCriterion = () => setModal({show: true, criterion: undefined});
  const editCriterion = (e, criterion) => {
    e.stopPropagation();
    setModal({show: true, criterion})
  }

  const reorderCriterion = (e, criterion) => {
    e.stopPropagation()
    setCriterionOrderModal({show: true, criterion})
  }
  const deleteCriterion = criterion => deleteModalContext.set({
    show: true,
    callback: () => criteriaApi.deleteCriterion(criterion.uuid).then(() => getCategory()),
    title: `le critère ${criterion.name}`,
  })

  return (
    <>
      <div className={classes.flexSpace}>
        <h3 className={classes.title}>🏷️ Critères</h3>
        <div style={{display: 'inline'}}>
          <Button className={classes.button} variant="dark" onClick={addCriterion}>Ajouter un critère</Button>
        </div>

      </div>
      <Table striped bordered hover variant="dark">
        <thead>
        <tr>
          <th style={{width: "4%"}}>Pos.</th>
          <th style={{width: "24%"}}>📁&nbsp;Groupe</th>
          <th>Nom</th>
          <th style={{width: "4%"}}>🎚️</th>
          <th style={{width: "20%"}}><AddBox/>&nbsp;/&nbsp;<IndeterminateCheckBox/>&nbsp;/&nbsp;🙋</th>
          <th style={{width: "12%"}}/>
        </tr>
        </thead>
        <tbody>
        {getCategoryCriteria(category)
          .filter(c => !c.is_separator)
          .sort((a, b) => a.order > b.order ? 1 : a.order < b.order ? -1 : 0).map(criterion => (
            <tr key={criterion.uuid} className={classes.row}>
              <td>{criterion.order ?? '-'}</td>
              <td>{criterion.group && category.groups.find(g => g.uuid === criterion.group)?.name}</td>
              <td>{criterion.name}</td>
              <td>{criterion.is_filter && <CheckIcon/>}</td>
              <td>
                <p style={{margin: 0}}>
                  {criterion.pro_con_title && !criterion.is_con && <AddBox style={{color: "green"}}/>}
                  {criterion.pro_con_title && criterion.is_con && <IndeterminateCheckBox style={{color: "red"}}/>}
                  &nbsp;{criterion.pro_con_title}
                  {criterion.faq_question && (<p>🙋 {criterion.faq_question}</p>)}
                </p>
              </td>
              <td className={classes.flexCenter}>
                <OverlayTrigger placement="bottom" overlay={
                  // eslint-disable-next-line react/no-unescaped-entities
                  <Tooltip id="button-tooltip-2">Modifier l'ordre du critère</Tooltip>
                }>
                  {({ref, ...triggerHandler}) => (
                    <DragHandleIcon {...triggerHandler} ref={ref} onClick={e => reorderCriterion(e, criterion)}/>
                  )}
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Modifier le critère</Tooltip>}>
                  {({ref, ...triggerHandler}) => (
                    <EditIcon {...triggerHandler} ref={ref} onClick={e => editCriterion(e, criterion)}/>
                  )}
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Supprimer le critère</Tooltip>}>
                  {({ref, ...triggerHandler}) => (
                    <DeleteIcon {...triggerHandler} ref={ref} onClick={() => deleteCriterion(criterion)}/>
                  )}
                </OverlayTrigger>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <UpdateCriterionModal
        show={modal.show}
        onHide={hideUpdateCriterionModal}
        criterion={modal.criterion}
        category={category}
        callback={getCategory}
      />
      <CriterionOrderModal
        show={criterionOrderModal.show}
        onHide={hideCriterionOrderModal}
        criterion={criterionOrderModal.criterion}
        callback={getCategory}
      />
    </>
  );
}

const useStyle = makeStyles(() => ({
  container: {
    margin: "72px 48px",
    boxSizing: "border-box",
  },
  flexSpace: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 72,
  },
  button: {
    padding: "8px 12px",
    height: "3rem",
    boxSizing: "border-box",
  },
  title: {
    marginBottom: 36,
  },
  flexCenter: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  row: {
    cursor: "pointer",
  },
}));

/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';

import {Button, Col, Form, Modal, Row, Table} from 'react-bootstrap';
import productApi from 'api/productApi';
import offerApi from "api/offerApi";

export default function ProductDeactivationModal ({show, onHide, product, callback}) {
  const base = {
    uuid: '',
    iceCatId: '',
    disabled: false,
    offers: [],
  };
  const [data, setData] = useState({...base});

  // eslint-disable-next-line no-unused-vars
  const onDeactivateOfferChange = (uuid, checked) => setData({
    ...data,
    offers: data.offers.map(o => ({...o, disabled: o.uuid === uuid ? checked : o.disabled}))
  })
  const onDeactivateProductChange = e => setData({...data, disabled: e.target.checked});
  const handleSave = async () => {
    data.disabled ? await productApi.deactivate(data.uuid) : await productApi.reactivate(data.uuid)
    data.offers.map(async o => await o.disabled ? offerApi.deactivate(o.uuid) : offerApi.reactivate(o.uuid))
    callback()
    onHide();
  }

  useEffect(() => {
    setData({...base, ...product, offers: product?.offers ?? []});
  }, [show, product]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Produit {data.name ?? ''}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>{data.name ?? ''}</h5>
        <Form.Group as={Row}>
          <Col sm="10">
            <Form.Check
              type="checkbox"
              label="Désactiver le produit"
              checked={data.disabled}
              // placeholder="Identifiant iceCat du champ ( id )"
              onChange={onDeactivateProductChange}
            />
          </Col>
        </Form.Group>
        <h5 style={{
          borderTop: "1px solid #ced4da",
          paddingTop: 16,
          paddingBottom: 8,
        }}>Offres
        </h5>
        <Table striped bordered hover variant="dark">
          <thead>
          <tr>
            <th>Source</th>
            <th>Boutique</th>
            <th>Condition</th>
            <th>Prix</th>
            <th>Désactiver</th>
          </tr>
          </thead>
          <tbody>
          {
            (data.offers ?? []).map(offer => (
              <tr key={offer.uuid}>
                <td>{offer.source}</td>
                <td>{offer.name}</td>
                <td>{offer.condition}</td>
                <td>
                  {(offer.price / 100).toLocaleString("fr-FR", {minimumFractionDigits: 2, maximumFractionDigits: 2})}&nbsp;€
                </td>
                <td>
                  <Form.Check
                    type="checkbox"
                    label="Désactive l'offre"
                    checked={offer.disabled}
                    onChange={e => onDeactivateOfferChange(offer.uuid, e.target.checked)}
                  />
                </td>
              </tr>
            ))
          }
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onHide}>
          Annuler
        </Button>
        <Button
          variant="dark"
          onClick={() => {
            handleSave();
          }}
        >
          Sauvegarder
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

import axios from 'axios';
import config from '../config';
import userService from '../services/userService';

const instance = axios.create({
  baseURL: config.apiUri,
  setTimeout: 300000,
  headers: {'Content-Type': 'application/json'},
});

instance.interceptors.request.use(request => {
  const token = userService.getAccessToken()
  if (token) {
    request.withCredentials = true
    request.headers['Authorization'] = 'Bearer ' + token
  }

  return request
}, error => Promise.reject(error));

instance.interceptors.response.use(response => response, async error => {
  const {config, response} = error;
  if (config.url !== '/login' && config.url !== '/refresh-token' && response?.status === 401 && !config._retry) {
    config._retry = true
    try {
      await userService.refreshToken()
      return instance(config)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  return Promise.reject(error)
})

export default instance

import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import {Button, Table} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import productApi from "api/productApi";
import offerApi from "api/offerApi";
import RestoreFromTrash from "@material-ui/icons/RestoreFromTrash";
import LogoutBar from "../../globalCompo/LogoutBar";
import Research from "./Research";
import SearchResult from "./SearchResult";
import ProductDeactivationModal from "./ProductDeactivationModal";

export default function ProductDeactivation () {
  const classes = useStyle();
  const history = useHistory();
  const [searchResult, setSearchResult] = useState({loading: false, products: []});
  const [deactivatedProducts, setDeactivatedProducts] = useState([]);
  const [deactivatedOffers, setDeactivatedOffers] = useState([]);
  const [modal, setModal] = useState({show: false, product: undefined});

  const search = text => {
    if (text.trim() === '') {
      return setSearchResult({...searchResult, loading: false, products: []})
    }
    setSearchResult({...searchResult, loading: true})
    productApi.searchByName(text).then(res => setSearchResult({...searchResult, loading: false, products: res.data.products}))
  }
  const onProductSelected = product => setModal({show: true, product: {...product, disabled: true}})
  const onReactivateOffer = (uuid) => offerApi.reactivate(uuid).then(() => getDeactivatedOffers())
  const getDeactivatedProducts = () => productApi.getDeactivatedProducts().then(res => setDeactivatedProducts(res.data))
  const getDeactivatedOffers = () => offerApi.getDeactivatedOffers().then(res => setDeactivatedOffers(res.data))
  const backtoHome = async () => history.push('/')
  const loadData = () => {
    getDeactivatedProducts()
    getDeactivatedOffers()
  }

  useEffect(() => loadData(), []);

  return (
    <>
      <LogoutBar/>
      <div className={classes.container}>
        <div className={classes.topButton}>
          <Button
            className={classes.button}
            variant="dark"
            onClick={backtoHome}
          >
            Retour
          </Button>
        </div>
        <div className={classes.flexSpace}>
          <h2 className={classes.title}>Management BDD</h2>
        </div>
        <Research
          onHandleSearch={search}
        />
        <div className={classes.flexSpace} style={{marginTop: 40}}>
          <h3 className={classes.title}>Résultats de recherche</h3>
        </div>
        <SearchResult
          {...searchResult}
          onProductSelected={onProductSelected}
        />

        <div className={classes.flexSpace} style={{marginTop: 40}}>
          <h3 className={classes.title}>Produits désactivés</h3>
        </div>
        <Table striped bordered hover variant="dark">
          <thead>
          <tr>
            <th>ID IceCat</th>
            <th>Nom</th>
          </tr>
          </thead>
          <tbody>
          {deactivatedProducts.map(product => (
            <tr
              key={product.uuid}
              className={classes.row}
              onClick={() => onProductSelected(product)}
            >
              <td>{product.icecatId}</td>
              <td>{product.name}</td>
            </tr>
          ))}
          </tbody>
        </Table>

        <div className={classes.flexSpace} style={{marginTop: 40}}>
          <h3 className={classes.title}>Offres désactivées</h3>
        </div>
        <Table striped bordered hover variant="dark">
          <thead>
          <tr>
            <th>Marchand</th>
            <th>Produit</th>
          </tr>
          </thead>
          <tbody>
          {deactivatedOffers.map(offer => (
            <tr key={offer.uuid} className={classes.row}>
              <td>{offer.name}</td>
              <td>{offer.product.name}</td>
              <td><RestoreFromTrash onClick={() => onReactivateOffer(offer.uuid)}/></td>
            </tr>
          ))}
          </tbody>
        </Table>

        <ProductDeactivationModal
          show={modal.show}
          onHide={() => { setModal({show: false, product: undefined}) }}
          product={modal.product}
          callback={loadData}
        />
      </div>
    </>
  );
}

const useStyle = makeStyles(() => ({
  container: {
    margin: "72px 48px",
    boxSizing: "border-box",
  },
  topButton: {
    margin: "36px 0",
  },
  flexSpace: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    padding: "8px 12px",
    height: "3rem",
    boxSizing: "border-box",
  },
  title: {
    marginBottom: 36,
  },
  row: {
    cursor: "pointer",
  },
}));

import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import {useParams} from "react-router-dom";
import {Button, OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import categoriesApi from "api/categoriesApi";
import LogoutBar from "../../../globalCompo/LogoutBar";
import EditIcon from "@material-ui/icons/Edit";
import UpdateLandingModal from "./UpdateLandingModal";
import CategoryMenu from "../CategoryMenu";

export default function Landings () {
  const classes = useStyle();
  const {uuid} = useParams();
  const [category, setCategory] = useState([]);
  const [landings, setLandings] = useState([]);
  const [modal, setModal] = useState({show: false, landing: undefined});

  const getCategory = () => categoriesApi.get(uuid).then(res => setCategory(res.data))
  const getLandings = () => categoriesApi.getLandings(uuid).then(res => setLandings(res.data))
  const addLanding = () => setModal({show: true, landing: undefined});

  useEffect(async () => {
    getCategory()
    getLandings()
  }, [uuid]);

  const onHide = () => setModal({show: false, landing: undefined});
  const editLanding = (e, landing) => {
    e.stopPropagation();
    setModal({show: true, landing});
  }

  return (
    <>
      <LogoutBar/>
      <div className={classes.container}>
        <CategoryMenu category={category} current="landings"/>
        <div className={classes.flexSpace}>
          <h2 className={classes.title}>Landings {category.name}</h2>
          <Button className={classes.button} variant="dark" onClick={addLanding}>
            Ajouter une landing
          </Button>
        </div>
        <Table striped bordered hover variant="dark">
          <thead>
          <tr>
            <th style={{width: "4%"}}>Pos.</th>
            <th>Nom</th>
            <th style={{width: "30%"}}>🏷️ Critères</th>
            <th style={{width: "30%"}}>URL</th>
            <th style={{width: "3%"}}/>
          </tr>
          </thead>
          <tbody>
          {landings?.sort((a, b) => {
            if (!a.order && !b.order) {
              return a.name > b.name ? 1 : -1
            }
            return !a.order || a.order > b.order ? 1 : -1
          }).map(landing => (
            <tr key={landing.slug} className={classes.row}>
              <td>{landing.order}</td>
              <td>{landing.name}</td>
              <td>{landing.criteria?.map(criterion => (<p key={criterion.uuid} style={{margin: 0}}>- {criterion.name}</p>))}</td>
              <td>{landing.segment && landing.segment + '/'}{landing.full_slug}</td>
              <td className={classes.flexCenter}>
                <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Modifier les informations</Tooltip>}>
                  {({ref, ...triggerHandler}) => (<EditIcon {...triggerHandler} ref={ref} onClick={e => editLanding(e, landing)}/>)}
                </OverlayTrigger>
              </td>
            </tr>
          ))}
          </tbody>
        </Table>
        <UpdateLandingModal
          show={modal.show}
          onHide={onHide}
          landing={modal.landing}
          category={category}
          callback={getLandings}
        />
      </div>
    </>
  );
}

const useStyle = makeStyles(() => ({
  container: {
    margin: "72px 48px",
    boxSizing: "border-box",
  },
  flexSpace: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 32,
    marginBottom: 16,
  },
  button: {
    padding: "8px 12px",
    height: "3rem",
    boxSizing: "border-box",
  },
  row: {
    cursor: "pointer",
  },
}));

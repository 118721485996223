const defaultTypeConfig = {
  valueSources: ['value'],
}

const defaultNumberType = {
  ...defaultTypeConfig,
  type: 'number',
  preferWidgets: ['number']
};

const defaultStringType = {
  ...defaultTypeConfig,
  type: 'text',
  excludeOperators: ["proximity", "starts_with", "ends_with"],
};

const defaultSelectType = {
  ...defaultTypeConfig, 
  type: 'select', 
  fieldSettings: {
    listValues: [], 
  }
}

export { defaultNumberType, defaultStringType, defaultSelectType };

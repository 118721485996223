// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/template';
import 'tinymce/plugins/wordcount';
import contentCss from 'tinymce/skins/content/default/content.min.css';
import contentUiCss from 'tinymce/skins/ui/oxide/content.min.css';

// Prevent Bootstrap dialog from blocking focusin
const tinymceFixEffect = () => {
  const fix = e => {
    if (e.target && e.target.closest && e.target.closest(".tox-tinymce, .tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
      e.stopImmediatePropagation();
    }
  }
  document.addEventListener('focus', fix, true);

  return () => {
    document.removeEventListener('focusin', fix, true);
  }
}

export {tinymceFixEffect, contentCss, contentUiCss}

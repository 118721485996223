/* eslint-disable react/prop-types */

import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {FormControl, InputGroup} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import {RemoveCircle} from '@material-ui/icons';

export default function Research ({onHandleSearch}) {
  const classes = useStyle();
  const [searchText, setSearchText] = useState('');

  const handleSearchText = e => {
    e.preventDefault;
    setSearchText(e.target.value);
  };

  const handleSearch = () => onHandleSearch(searchText);

  const onRemove = () => {
    setSearchText('');
    onHandleSearch('');
  };

  return (
    <div style={{position: 'relative'}}>
      <InputGroup className="mb-3">
        <FormControl
          placeholder="Saisir ou coller l'objet de recherche"
          aria-describedby="basic-search"
          className={classes.searchbar}
          value={searchText}
          onChange={handleSearchText}
        />
        <InputGroup.Append>
          <Button
            className={classes.searchbar}
            variant="dark"
            onClick={handleSearch}
            disabled={searchText.trim() === ''}
          >
            Rechercher
          </Button>
        </InputGroup.Append>
      </InputGroup>
      <div className={classes.clearSearch} onClick={onRemove}>
        <RemoveCircle/>
      </div>
    </div>
  )
}

const useStyle = makeStyles(() => ({
  searchbar: {
    padding: "8px 12px",
    height: "3rem",
    boxSizing: "border-box",
  },
  clearSearch: {
    position: 'absolute',
    top: 8,
    right: 120,
    zIndex: 999,
    color: '#ccc',
    cursor: 'pointer',
  },
}))

/* eslint-disable react/prop-types */
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import {OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import EditIcon from "@material-ui/icons/Edit";
import UpdateFeatureModal from "./UpdateFeatureModal";
import LogoutBar from "../../../globalCompo/LogoutBar";
import categoriesApi from "../../../api/categoriesApi";
import {useParams} from "react-router-dom";
import CategoryMenu from "../CategoryMenu";

export default function Features () {
  const classes = useStyle();
  const {uuid} = useParams();
  const [features, setFeatures] = useState([]);
  const [modal, setModal] = useState({show: false, feature: undefined});
  const [category, setCategory] = useState([]);

  const getCategory = () => categoriesApi.get(uuid).then(res => setCategory(res.data))

  useEffect(() => {
    getCategory()
  }, [uuid]);

  useEffect(() => {
    if (category.features) {
      setFeatures(category.features);
    }
  }, [category.features])

  const onHide = () => setModal({show: false, feature: undefined});
  const editFeature = (e, feature) => {
    e.stopPropagation();
    setModal({show: true, feature});
  }

  return (
    <>
      <LogoutBar/>
      <div className={classes.container}>
        <CategoryMenu category={category} current="features"/>
        <h4>Master</h4>
        <Table striped bordered hover variant="dark">
          <thead>
          <tr>
            <th style={{width: "4%"}}>Pos.</th>
            <th style={{width: "10%"}}>Libellé</th>
            <th style={{width: "6%"}}># Icecat</th>
            <th>Nom complet</th>
            <th style={{width: "20%"}}>Valeurs spécifiques</th>
            <th style={{width: "5%"}}/>
          </tr>
          </thead>
          <tbody>
          {features?.filter(f => f.order).sort((a, b) => {
            if (!a.order && !b.order) {
              return a.name > b.name ? 1 : -1
            }
            return !a.order || a.order > b.order ? 1 : -1
          }).map(feature => (
            <tr key={feature.uuid} className={classes.row}>
              <td>{feature.order}</td>
              <td>{feature.label ?? '-'}</td>
              <td>{feature.iceCatId}</td>
              <td>{feature.name}</td>
              <td>{feature.values.map(v => v.value).join(', ')}</td>
              <td className={classes.flexCenter}>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      Modifier les informations
                    </Tooltip>
                  }
                >
                  {({ref, ...triggerHandler}) => (
                    <EditIcon
                      {...triggerHandler}
                      ref={ref}
                      onClick={e => editFeature(e, feature)}
                    />
                  )}
                </OverlayTrigger>
              </td>
            </tr>
          ))}
          </tbody>
        </Table>
        <h4>Autres</h4>
        <Table striped bordered hover variant="dark">
          <thead>
          <tr>
            <th style={{width: "6%"}}># Icecat</th>
            <th>Nom</th>
            <th style={{width: "35%"}}>URL</th>
            <th style={{width: "20%"}}>Valeurs spécifiques</th>
            <th style={{width: "5%"}}/>
          </tr>
          </thead>
          <tbody>
          {features?.filter(f => !f.order).sort((a, b) => {
            if (!a.order && !b.order) {
              return a.name > b.name ? 1 : -1
            }
            return !a.order || a.order > b.order ? 1 : -1
          }).map(feature => (
            <tr key={feature.uuid} className={classes.row}>
              <td>{feature.iceCatId}</td>
              <td>{feature.name}</td>
              <td>{feature.url}</td>
              <td>{feature.values.map(v => v.value).join(', ')}</td>
              <td className={classes.flexCenter}>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      Modifier les informations
                    </Tooltip>
                  }
                >
                  {({ref, ...triggerHandler}) => (
                    <EditIcon
                      {...triggerHandler}
                      ref={ref}
                      onClick={e => editFeature(e, feature)}
                    />
                  )}
                </OverlayTrigger>
              </td>
            </tr>
          ))}
          </tbody>
        </Table>
        <UpdateFeatureModal
          show={modal.show}
          onHide={onHide}
          feature={modal.feature}
          category={category}
          callback={getCategory}
        />
      </div>
    </>
  );
}

const useStyle = makeStyles(() => ({
  container: {
    margin: "72px 48px",
    boxSizing: "border-box",
  },
  flexSpace: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 72,
  },
  button: {
    padding: "8px 12px",
    height: "3rem",
    boxSizing: "border-box",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  title: {
    marginBottom: 36,
  },
  row: {
    cursor: "pointer",
  },
}));

/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Col, Form, Modal, Row} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import criteriaApi from "../../../api/criteriaApi";

export default function CriterionOrderModal ({show, criterion, onHide, callback}) {
  const base = {order: null};
  const [data, setData] = useState({...base});
  const changePosition = e => setData({...data, order: e.target.value})

  const save = () => {
    criterion ? criteriaApi.saveOrder(data).then(callback) : callback();
    onHide();
  };

  useEffect(() => {
    setData({...base, order: criterion?.order, uuid: criterion?.uuid});
  }, [show, criterion]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Position du critère {criterion ? "-" : ""} {criterion?.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Row} controlId="fornOrder">
          <Form.Label column sm="2">
            Position
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="number"
              placeholder="Position du critère"
              value={data.order || ""}
              onChange={e => changePosition(e)}
            />
          </Col>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onHide}>
          Annuler
        </Button>
        <Button variant="dark" onClick={save}>
          {criterion ? "Sauvegarder" : "Créer"}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

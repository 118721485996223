/* eslint-disable react/no-unescaped-entities */
import React, {createContext, useState} from "react";
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";

export const DeleteModalContext = createContext({});

// eslint-disable-next-line react/prop-types
export default function DeleteModal ({children}) {
  const baseContext = {
    show: false,
    title: "",
    callback: () => null,
  };

  const [context, setContext] = useState({...baseContext});
  const close = () => setContext({...baseContext});

  const confirm = () => {
    context.callback();
    close()
  };

  return (
    <DeleteModalContext.Provider value={{set: setContext}}>
      <Modal
        show={context.show}
        onHide={close}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Suppression d'un élément
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer {context.title} ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={close}>
            Annuler
          </Button>
          <Button variant="dark" onClick={confirm}>
            Confirmer
          </Button>
        </Modal.Footer>
      </Modal>
      {children}
    </DeleteModalContext.Provider>
  );
}

/* eslint-disable react/prop-types */
import React, {useEffect, useState} from "react";

import {Col, Form, Modal, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";

import categoriesApi from "../../api/categoriesApi";

export default function UpdateCategoryModal ({show, category, onHide, callback}) {
  const base = {
    name: "",
    plural: "",
    is_female: false,
    catId: "",
  };
  const [data, setData] = useState({...base});

  useEffect(() => {
    setData({...base, ...category});
  }, [show, category]);

  const onSave = () => {
    category ? categoriesApi.update(data).then(callback) : categoriesApi.create(data).then(callback)
    onHide()
  };

  const onIcecatIdChange = e => setData({...data, catId: e.target.value});
  const onDescriptionChange = e => setData({...data, description: e.target.value});
  const onNameChange = e => setData({...data, name: e.target.value});
  const onPluralChange = e => setData({...data, plural: e.target.value});
  const onSetFemale = e => setData({...data, is_female: e.target.checked});

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Catégorie {category ? "-" : ""} {category?.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Row} controlId="formFemale">
          <Form.Label column sm="2">
            Nom
          </Form.Label>
          <Col sm="10" className="d-flex flex-row align-items-center">
            <Form.Check
              type="checkbox"
              label="Nom féminin"
              checked={data.is_female}
              onChange={onSetFemale}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formName">
          <Form.Label column sm="2"/>
          <Col sm="1" className="d-flex flex-row align-items-center">
            {data.is_female ? 'Une' : 'Un'}
          </Col>
          <Col sm="9">
            <Form.Control
              type="text"
              placeholder="Nom de la catégorie au singulier"
              value={data.name || ""}
              onChange={onNameChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formPlural">
          <Form.Label column sm="2"/>
          <Col sm="1" className="d-flex flex-row align-items-center">
            Des
          </Col>
          <Col sm="9">
            <Form.Control
              type="text"
              placeholder="Nom de la catégorie au pluriel"
              value={data.plural || ""}
              onChange={onPluralChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formIceCatId">
          <Form.Label column sm="2">
            Categorie IceCat
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              placeholder="Identifiant iceCat de la catégorie"
              onChange={onIcecatIdChange}
              value={data.catId || ""}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formDescription">
          <Form.Label column sm="2">
            Intro
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              placeholder="Texte intro de la catégorie"
              onChange={onDescriptionChange}
              value={data.description || ""}
            />
          </Col>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onHide}>
          Annuler
        </Button>
        <Button
          variant="dark"
          onClick={onSave}
        >
          {category ? "Sauvegarder" : "Créer"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

import api from "./api";

const getAll = async () => await api.get('/categories', {params: {include_inactive: true}});
const create = async data => await api.post('/categories', {data});
const get = async uuid => await api.get(`/categories/${uuid}`, {params: {full: true}});
const update = async data => await api.put(`/categories/${data.uuid}`, {data});
const addCriterion = async data => await api.post(`/categories/${data.uuid}/criteria`, {data});
const getLandings = async uuid => await api.get(`/categories/${uuid}/landings`);
const addLanding = async data => await api.post(`/categories/${data.uuid}/landings`, {data});
const updateLanding = async data => await api.put(`/categories/${data.uuid}/landings/${data.slug}`, {data});

export default {getAll, get, update, create, addCriterion, getLandings, addLanding, updateLanding};

/* eslint-disable react/prop-types */
import {makeStyles} from '@material-ui/core';
import React from 'react';
import LinkIcon from "@material-ui/icons/Link";

// eslint-disable-next-line no-unused-vars
export default function SearchResult ({loading, products, onProductSelected}) {
  const classes = useStyle();

  // eslint-disable-next-line no-unused-vars
  const displayResult = products => {
    if (products.length === 0) {
      return (
        <p>Aucun résultat</p>
      );
    }
    return (
      <ul className={classes.ul}>
        {products.map((product, idx) => (
          <li key={product.uuid} className={classes.li} style={idx % 2 !== 0 ? {background: 'none'} : {}}>
          <a href={`https://www.seerkle.com/produits/${product.slug}`} target="_blank" rel="noreferrer" className={classes.productLink}>
            <LinkIcon/>
          </a>
          <span className={classes.span} onClick={() => onProductSelected(product)}>
            {product.name}
          </span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className={classes.loading}>
      {
        loading ?
          <div>Chargement...</div> :
          <div> {displayResult(products)} </div>
      }
    </div>
  )
}

const useStyle = makeStyles(() => ({
  loading: {
    textAlign: 'center',
  },
  ul: {
    listStyle: 'none',
    padding: 0,
  },
  li: {
    display: 'flex',
    flexDirection: 'row',
    padding: 5,
    margin: 0,
    backgroundColor: '#e0e0e0',
    cursor: 'pointer',
  },
  span: {
    flexGrow: 1,
    textAlign: 'left',
  },
  productLink: {
    marginRight: '2rem',
  },
}));

import React, {useState} from 'react';
import userService from '../../services/userService';

const defaultUser = {
  user: '',
  password: '',
  loading: false,
  submitted: false,
  error: '',
};

export default function Login () {
  const [data, setData] = useState(defaultUser);

  const handleChange = e => {
    let newData = {...data};
    const {name, value} = e.target;
    newData[name] = value;
    setData(newData);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setData({...data, submitted: true});
    const {username, password} = data;
    if (!(username && password)) {
      return;
    }
    setData({...data, submitted: true});
    try {
      await userService.authenticate(username, password);
      location.href = '/';
    } catch (error) {
      setData({...data, error: error.message, loading: false, submitted: false})
    }
  };

  return (
    <div style={{
      display: 'block',
      maxWidth: 400,
      margin: 'auto',
    }}>
      <h2>Login</h2>
      <form name="form" onSubmit={handleSubmit}>
        <div className={'form-group'}>
          <label htmlFor="username">Adresse email</label>
          <input type="text" className="form-control" name="username" value={data.username} onChange={handleChange}/>
        </div>
        <div className={'form-group'}>
          <label htmlFor="password">Mot de passe</label>
          <input type="password" className="form-control" name="password" value={data.password} onChange={handleChange}/>
        </div>
        <div className="form-group">
          <button className="btn btn-dark btn-block dark" disabled={data.loading}>Login</button>
        </div>
        {data.error &&
          <div className={'alert alert-danger'}>{data.error}</div>
        }
      </form>
    </div>
  )
}

import usersApi from '../api/usersApi';

const getUser = () => JSON.parse(localStorage.getItem('user')) || {}
const setUser = user => localStorage.setItem('user', JSON.stringify(user))
const deleteUser = () => localStorage.removeItem('user')

const logout = deleteUser

const authenticate = async (username, password) => new Promise((resolve, reject) => {
  usersApi.login(username, password)
    .then(response => {
      const {data: user} = response
      if (user) {
        setUser(user)
      }
      resolve(user)
    })
    .catch(err => {
      const {data, status, statusText} = err.response ?? {data: null, status: 400, statusText: err.cause}
      if (status === 401) {
        logout()
        location.reload()
      }
      reject(Error((data && data.message) || statusText))
    })
})

const getAccessToken = () => getUser()?.token

const refreshToken = async () => {
  const user = getUser()
  const refreshToken = user?.refresh_token
  if (refreshToken) {
    const data = await usersApi.refreshToken(refreshToken)
    setUser({...user, token: data.token})
  }
}

export default {authenticate, logout, getAccessToken, refreshToken};

/* eslint-disable react/prop-types */
import React, {useCallback, useEffect, useState} from 'react';
import {Builder, Query, Utils as QbUtils} from 'react-awesome-query-builder';
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
import 'react-awesome-query-builder/lib/css/styles.css';
import {defaultNumberType, defaultSelectType, defaultStringType} from '../../../config/fieldMapping';

export default function CriterionQueryBuilder ({jsonTree, category, onQueryBuilderChange}) {
  const defaultJsonTree = {"id": QbUtils.uuid(), "type": "group"};
  const defaultConfig = {...MaterialConfig, fields: {}}
  const [state, setState] = useState({
    tree: null,
    config: defaultConfig
  })

  /** Formats fields in order to comply to react-awesome-builder format */
  const computeFields = features => {
    let fields = {
      '-1': {...defaultStringType, label: 'Marque'},
      '-2': {...defaultStringType, label: 'Marchand'},
      '-3': {...defaultNumberType, label: 'Prix'},
      '-4': {...defaultStringType, label: 'Nom'},
    }
    const categoryIds = category.children?.map(c => c.catId) ?? [];
    if (categoryIds.length) {
      fields[0] = {...defaultSelectType, label: 'Catégorie Icecat', fieldSettings: {listValues: categoryIds}}
    }
    features
      .sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
      .forEach(feature => fields[Number.parseInt(feature.iceCatId)] = {
        ...(feature.isNumerical ? defaultNumberType : defaultStringType),
        label: `${feature.iceCatId} - ${feature.name}`,
      })

    return fields
  }

  useEffect(() => {
    if (category) {
      setState(prevState => {
        const config = {...prevState.config, fields: computeFields(category.features)}
        return {...prevState, tree: QbUtils.checkTree(QbUtils.loadTree(jsonTree ?? defaultJsonTree), config), config}
      })
    }
  }, [category, jsonTree])

  const onChange = useCallback((immutableTree, config) => {
    setState(prevState => ({...prevState, tree: immutableTree, config}));
    onQueryBuilderChange({jsonLogic: QbUtils.jsonLogicFormat(immutableTree, config), jsonTree: QbUtils.getTree(immutableTree)});
  }, []);

  const renderBuilder = useCallback(props => (
    <div className="query-builder-container" style={{padding: "10px"}}>
      <div className="query-builder qb-dark">
        <Builder {...props} />
      </div>
    </div>
  ), []);

  return (state.tree &&
    <div>
      <Query
        {...state.config}
        value={state.tree}
        onChange={onChange}
        renderBuilder={renderBuilder}
      />
      <div className="query-builder-result"/>
    </div>
  );
}

/* eslint-disable react/prop-types */
import React, {useEffect, useRef, useState} from "react";
import {Col, Form, Modal, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import categoriesApi from "../../../api/categoriesApi";
import {tinymceFixEffect} from "../../../services/tinymceService";
import {Editor} from "@tinymce/tinymce-react";
import contentUiCss from "tinymce/skins/ui/oxide/content.min.css";
import contentCss from "tinymce/skins/content/default/content.min.css";

export default function UpdateLandingModal ({show, landing, category, onHide, callback}) {
  const base = {
    name: '',
    slug: '',
    full_slug: '',
    intro: '',
    order: 1,
    criteria: []
  };
  const [data, setData] = useState({...base})
  const introEditorRef = useRef(null);
  const [intro, setIntro] = useState('')

  useEffect(() => {
    setData({...base, ...landing})
    setIntro(landing?.intro)
  }, [show, landing]);

  useEffect(tinymceFixEffect)

  const changeName = e => setData({...data, name: e.target.value})
  const changeIntro = () => setData(prevData => ({...prevData, intro: introEditorRef.current?.getContent() || null}))
  const checkRequiredFields = () => data.name?.trim()

  const changeCriterion = (e, index) => setData(prevData => ({
    ...prevData, criteria: [
      ...prevData.criteria.slice(0, index),
      ...[category.criteria.find(c => c.uuid === e.target.value)].filter(c => c !== undefined),
      ...prevData.criteria.slice(index + 1)
    ]
  }));

  const handleSave = () => {
    if (!checkRequiredFields()) {
      return false;
    }
    landing ? categoriesApi.updateLanding({...data, uuid: category.uuid, slug: landing.slug}).then(callback)
      : categoriesApi.addLanding({...data, uuid: category.uuid}).then(callback)
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Landing {landing ? "-" : ""} {landing?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Informations</h5>
        <Form.Group as={Row} controlId="formName">
          <Form.Label column sm="2">Nom</Form.Label>
          <Col sm="10">
            <Form.Control type="text" placeholder="Nom de la landing" value={data.name || ""} onChange={changeName}/>
          </Col>
        </Form.Group>

        {landing && <Form.Group as={Row}>
          <Form.Label column sm="2">URL</Form.Label>
          <Col sm="10">
            <Form.Control type="text" placeholder="Slug URL complet" value={data.full_slug || ""} readOnly={true}/>
          </Col>
        </Form.Group>
        }

        <Form.Group as={Row} controlId="formIntro">
          <Form.Label column sm="2">Intro</Form.Label>
          <Col sm="10">
            <Editor
              onInit={(evt, intro) => introEditorRef.current = intro}
              initialValue={intro}
              onChange={changeIntro}
              init={{
                height: 200,
                skin: false,
                content_css: false,
                content_style: contentUiCss.toString() + '\n' + contentCss.toString(),
                menubar: false,
                plugins: [
                  'autolink lists link charmap preview anchor',
                  'searchreplace',
                  'paste wordcount'
                ],
                toolbar: 'undo redo | bold italic | removeformat',
              }}
            />
          </Col>
        </Form.Group>

        <h5 style={{borderTop: "1px solid #ced4da", paddingTop: 16, paddingBottom: 8}}>
          Critères
        </h5>
        {data.criteria.map((criterion, index) => (
          <Form.Group as={Row} key={index}>
            <Form.Label column sm="2">Critère {index + 1}</Form.Label>
            <Col sm="10">
              <select onChange={e => changeCriterion(e, index)} value={criterion.uuid || ''}>
                <option value=''>--- Aucun ---</option>
                {(category.criteria ?? []).filter(c => (c.is_filter || c.segment) && !c.is_separator)
                  .filter(c => {
                    const i = data.criteria.map(c2 => c2.uuid).indexOf(c.uuid)
                    return i === -1 || i === index
                  })
                  .sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
                  .map(c => (<option key={c.uuid} value={c.uuid}>{c.name}</option>))}
              </select>
            </Col>
          </Form.Group>
        ))}
        {data.criteria.length < 3 && (
          <Form.Group as={Row}>
            <Form.Label column sm="2">Critère {data.criteria.length + 1}</Form.Label>
            <Col sm="10">
              <select onChange={e => changeCriterion(e, data.criteria.length)} value=''>
                <option value=''>--- Aucun ---</option>
                {(category.criteria ?? []).filter(c => (c.is_filter || c.segment) && !c.is_separator)
                  .filter(c => data.criteria.map(c2 => c2.uuid).indexOf(c.uuid) === -1)
                  .sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
                  .map(c => (<option key={c.uuid} value={c.uuid}>{c.name}</option>))}
              </select>
            </Col>
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onHide}>Annuler</Button>
        <Button disabled={!checkRequiredFields()} variant="dark" onClick={() => handleSave()}>
          {landing?.slug ? "Sauvegarder" : "Créer"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

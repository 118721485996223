/* eslint-disable react/prop-types */
import React, {useContext, useState} from "react";
import {Button, OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import {makeStyles} from "@material-ui/core";
import {DeleteModalContext} from "../../../globalCompo/DeleteModal";
import criteriaGroupsApi from "../../../api/criteriaGroupsApi";
import UpdateCriteriaGroupModal from "./UpdateCriteriaGroupModal";
import {Check, Delete, Edit} from "@material-ui/icons";

export default function CriteriaGroups ({category, getCategory}) {
  const classes = useStyle()
  const deleteModalContext = useContext(DeleteModalContext)
  const [modal, setModal] = useState({show: false, group: undefined})

  const getCategoryCriteriaGroups = category => category?.groups ?? []
  const hideUpdateGroupModal = () => setModal({show: false, group: undefined});
  const addGroup = () => setModal({show: true, group: undefined});
  const editGroup = (e, group) => {
    e.stopPropagation();
    setModal({show: true, group})
  }

  const deleteGroup = group => deleteModalContext.set({
    show: true,
    callback: () => criteriaGroupsApi.deleteGroup(group.uuid).then(() => getCategory()),
    title: `le groupe ${group.name}`,
  })

  return (
    <>
      <div className={classes.flexSpace}>
        <h3 className={classes.title}>📁 Groupes de critères</h3>
        <div style={{display: 'inline'}}>
          <Button className={classes.button} variant="dark" onClick={addGroup}>
            Ajouter un groupe
          </Button>
        </div>
      </div>
      <Table striped bordered hover variant="dark">
        <thead>
        <tr>
          <th style={{width: "4%"}}>Pos.</th>
          <th>Nom</th>
          <th style={{width: "12%"}}>⚛️&nbsp;Fusionnable</th>
          <th style={{width: "8%"}}/>
        </tr>
        </thead>
        <tbody>
        {getCategoryCriteriaGroups(category).sort((a, b) => a.order > b.order ? 1 : a.order < b.order ? -1 : 0).map(group => (
          <tr key={group.uuid} className={classes.row}>
            <td>{group.order}</td>
            <td>{group.name}</td>
            <td>{group.is_or && <Check/>}</td>
            <td className={classes.flexCenter}>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="button-tooltip-2">Modifier le groupe</Tooltip>
                }
              >
                {({ref, ...triggerHandler}) => (
                  <Edit
                    {...triggerHandler}
                    ref={ref}
                    onClick={e => editGroup(e, group)}
                  />
                )}
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="button-tooltip-2">Supprimer le groupe</Tooltip>
                }
              >
                {({ref, ...triggerHandler}) => (
                  <Delete
                    {...triggerHandler}
                    ref={ref}
                    onClick={() => deleteGroup(group)}
                  />
                )}
              </OverlayTrigger>
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
      <UpdateCriteriaGroupModal
        show={modal.show}
        onHide={hideUpdateGroupModal}
        group={modal.group}
        category={category}
        callback={getCategory}
      />
    </>
  );
}

const useStyle = makeStyles(() => ({
  container: {
    margin: "72px 48px",
    boxSizing: "border-box",
  },
  flexSpace: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 72,
  },
  button: {
    padding: "8px 12px",
    height: "3rem",
    boxSizing: "border-box",
  },
  title: {
    marginBottom: 36,
  },
  flexCenter: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  row: {
    cursor: "pointer",
  },
}));

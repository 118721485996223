import React from 'react';
import {Navbar} from 'react-bootstrap';
import {makeStyles} from '@material-ui/core';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import userService from '../services/userService';

export default function LogoutBar () {
  const classes = useStyle();
  const disconnect = (e) => {
    e.preventDefault();
    userService.logout();
    location.reload();
  }
  return (
    <>
      <Navbar>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text className={classes.logoutStyle}>
            <a
              className={classes.logoutStyle}
              href="#"
              onClick={disconnect}
            >
              <span><LogoutIcon/></span>Se déconnecter
            </a>
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

const useStyle = makeStyles({
  logoutStyle: {
    color: '#fff',
  }
})

import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import {useParams} from "react-router-dom";
import Criteria from "./Criteria/Criteria";
import categoriesApi from "api/categoriesApi";
import LogoutBar from "../../globalCompo/LogoutBar";
import CriteriaGroups from "./CriteriaGroups/CriteriaGroups";
import CategoryMenu from "./CategoryMenu";

export default function Category () {
  const classes = useStyle();
  const {uuid} = useParams();
  const [category, setCategory] = useState([]);

  const getCategory = () => categoriesApi.get(uuid).then(res => setCategory(res.data))

  useEffect(() => {
    getCategory()
  }, [uuid]);

  return (
    <>
      <LogoutBar/>
      <div className={classes.container}>
        <CategoryMenu category={category}/>
        <CriteriaGroups category={category} getCategory={getCategory}/>
        <Criteria category={category} getCategory={getCategory}/>
      </div>
    </>
  );
}

const useStyle = makeStyles(() => ({
  container: {
    margin: "72px 48px",
    boxSizing: "border-box",
  },
  flexSpace: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 32,
  },
  button: {
    padding: "8px 12px",
    height: "3rem",
    boxSizing: "border-box",
  },
}));

/* eslint-disable react/prop-types */
import React, {useEffect, useState} from "react";
import {Col, Form, Modal, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import criteriaGroupsApi from "../../../api/criteriaGroupsApi";

export default function UpdateCriteriaGroupModal ({show, group, onHide, callback, category}) {
  const base = {
    uuid: null,
    name: '',
    is_or: false,
  };
  const [data, setData] = useState({...base});

  const changeName = e => setData(prevData => ({...prevData, name: e.target.value}));
  const changeIsOr = e => setData(prevData => ({...prevData, is_or: e.target.checked}));
  const save = () => {
    data.uuid ? criteriaGroupsApi.update(data).then(callback) : criteriaGroupsApi.create(category.uuid, data).then(callback);
    onHide();
  };

  useEffect(() => {
    setData({...base, ...(group ?? {})});
  }, [show, group]);

  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Groupe {data.name ? "-" : ""} {data.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Row} controlId="formName">
          <Form.Label column sm="2">Nom</Form.Label>
          <Col sm="10">
            <Form.Control type="text" placeholder="Nom du groupe" value={data.name || ""} onChange={changeName}/>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formFemale">
          <Form.Label column sm="2"></Form.Label>
          <Col sm="10" className="d-flex flex-row align-items-center">
            <Form.Check type="checkbox" label="⚛️ Fusionnable" checked={data.is_or} onChange={changeIsOr}/>
          </Col>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onHide}>Annuler</Button>
        <Button variant="dark" onClick={save}>{data.uuid ? "Sauvegarder" : "Créer"}</Button>
      </Modal.Footer>
    </Modal>
  );
}

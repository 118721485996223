/* eslint-disable react/prop-types */
import React, {useEffect, useRef, useState} from "react";
import {Col, Form, Modal, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import criteriaApi from "../../../api/criteriaApi";
import CriterionQueryBuilder from "./CriterionQueryBuilder";
import {Editor} from '@tinymce/tinymce-react';

import {contentCss, contentUiCss, tinymceFixEffect} from '../../../services/tinymceService'

export default function UpdateCriterionModal ({show, criterion, onHide, callback, category}) {
  const base = {
    uuid: null,
    name: '',
    logic_tree: criterion?.logic_tree ?? null,
    logic: criterion?.logic ?? null,
    is_female: null,
    is_filter: true,
    filter_name: null,
    segment: null,
    group: null,
    slug: null,
    description: null,
    intro: null,
    is_segment_carousel: false,
    is_con: false,
    pro_con_title: null,
    pro_con: null,
    faq_question: null,
    faq_answer: null,
  };
  const [data, setData] = useState({...base});
  const proConEditorRef = useRef(null);
  const faqAnswerEditorRef = useRef(null);
  const [proCon, setProCon] = useState('')
  const [faqAnswer, setFaqAnswer] = useState('')

  const changeTree = ({jsonTree, jsonLogic}) => setData(prevData => ({...prevData, logic_tree: jsonTree, logic: jsonLogic}));
  const changeName = e => setData(prevData => ({...prevData, name: e.target.value}));
  const changeFemale = e => setData(prevData => ({...prevData, is_female: e.target.value !== '' ? !!parseInt(e.target.value) : null}))
  const changeFilter = e => setData(prevData => ({...prevData, is_filter: e.target.checked}));
  const changeFilterName = e => setData(prevData => ({...prevData, filter_name: e.target.value || null}));
  const changeGroup = e => setData(prevData => ({...prevData, group: e.target.value || null}));
  const changeSlug = e => setData(prevData => ({...prevData, slug: e.target.value || null}));
  const changeDescription = e => setData(prevData => ({...prevData, description: e.target.value || null}));
  const changeIsCon = e => setData(prevData => ({...prevData, is_con: e.target.checked}));
  const changeProConTitle = e => setData(prevData => ({...prevData, pro_con_title: e.target.value || null}));
  const changeProCon = () => setData(prevData => ({...prevData, pro_con: proConEditorRef.current?.getContent() || null}));
  const changeFaqQuestion = e => setData(prevData => ({...prevData, faq_question: e.target.value || null}));
  const changeFaqAnswer = () => setData(prevData => ({...prevData, faq_answer: faqAnswerEditorRef.current?.getContent() || null}));
  const save = () => {
    data.uuid ? criteriaApi.update(data).then(callback) : criteriaApi.create(category.uuid, data).then(callback);
    onHide();
  };

  useEffect(() => {
    setData({...base, ...(criterion ?? {})});
    setProCon(criterion?.pro_con)
    setFaqAnswer(criterion?.faq_answer)
  }, [show, criterion]);

  useEffect(tinymceFixEffect)

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Critère {data.name ? "-" : ""} {data.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Row} controlId="formName">
          <Form.Label column sm="3">
            🏷️ Nom
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="text"
              placeholder="Nom du critère"
              value={data.name || ""}
              onChange={changeName}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formFemale">
          <Form.Label column sm="3">
            ♀️ Nom féminin
          </Form.Label>
          <Col sm="9">
            <select onChange={changeFemale} value={data.is_female === true ? '1' : data.is_female === false ? '0' : ''}>
              <option value=''>Idem catégorie ({category.is_female ? 'Oui' : 'Non'})</option>
              <option value='1'>Oui</option>
              <option value='0'>Non</option>
            </select>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formSlug">
          <Form.Label column sm="3">
            🐌 Slug URL
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="text"
              placeholder="Slug"
              value={data.slug}
              onChange={changeSlug}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formDescription">
          <Form.Label column sm="3">
            🪪 Nom sur carte
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="text"
              placeholder={data.name}
              value={data.description}
              onChange={changeDescription}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formFilter">
          <Form.Label column sm="3">
            🎚️ Filtre
          </Form.Label>
          <Col sm="9">
            <Form.Check
              type="checkbox"
              label="Utiliser comme filtre"
              checked={data.is_filter}
              onChange={changeFilter}
            />
          </Col>
        </Form.Group>
        {data.is_filter &&
          <Form.Group as={Row} controlId="formFilterName">
            <Form.Label column sm="3">
              🎚️ Nom du filtre
            </Form.Label>
            <Col sm="9">
              <Form.Control
                type="text"
                placeholder="Nom filtre"
                value={data.filter_name}
                onChange={changeFilterName}
              />
            </Col>
          </Form.Group>
        }
        {data.is_filter &&
          <Form.Group as={Row} controlId="formGroup">
            <Form.Label column sm="3">📁 Groupe</Form.Label>
            <Col sm="9">
              <select onChange={changeGroup} value={data.group}>
                <option value=''>--- Non groupé ---</option>
                {(category.groups ?? []).map(g => (<option key={g.uuid} value={g.uuid}>{g.name}</option>))}
              </select>
            </Col>
          </Form.Group>
        }
        <Form.Group as={Row} controlId="formProConTitle">
          <Form.Label column sm="3">
            ± Avantage / inconvénient
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="text"
              placeholder="Titre avantage / inconvénient"
              value={data.pro_con_title}
              onChange={changeProConTitle}
            />
          </Col>
        </Form.Group>
        {data.pro_con_title &&
          <Form.Group as={Row} controlId="formIsCon">
            <Form.Label column sm="3"></Form.Label>
            <Col sm="9">
              <Form.Check
                type="checkbox"
                label="Est un inconvénient"
                checked={data.is_con}
                onChange={changeIsCon}
              />
            </Col>
          </Form.Group>
        }
        {data.pro_con_title &&
          <Form.Group as={Row} controlId="formProCon">
            <Form.Label column sm="3">
              Description avantage / inconvénient
            </Form.Label>
            <Col sm="9">
              <Editor
                onInit={(evt, editor) => proConEditorRef.current = editor}
                initialValue={proCon}
                onChange={changeProCon}
                init={{
                  height: 200,
                  skin: false,
                  content_css: false,
                  content_style: contentUiCss.toString() + '\n' + contentCss.toString(),
                  menubar: false,
                  plugins: [
                    'autolink lists link charmap preview anchor',
                    'searchreplace',
                    'paste wordcount'
                  ],
                  toolbar: 'undo redo | bold italic | removeformat',
                }}
              />
            </Col>
          </Form.Group>
        }
        <Form.Group as={Row} controlId="formFaq">
          <Form.Label column sm="3">
            🙋 Question
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="text"
              placeholder="Libellé question"
              value={data.faq_question}
              onChange={changeFaqQuestion}
            />
          </Col>
        </Form.Group>
        {data.faq_question &&
          <Form.Group as={Row} controlId="formFaq">
            <Form.Label column sm="3">
              Réponse
            </Form.Label>
            <Col sm="9">
              <Editor
                onInit={(evt, editor) => faqAnswerEditorRef.current = editor}
                initialValue={faqAnswer}
                onChange={changeFaqAnswer}
                init={{
                  height: 200,
                  skin: false,
                  content_css: false,
                  content_style: contentUiCss.toString() + '\n' + contentCss.toString(),
                  menubar: false,
                  plugins: [
                    'autolink lists link charmap preview anchor',
                    'searchreplace',
                    'paste wordcount'
                  ],
                  toolbar: 'undo redo | bold italic | removeformat',
                }}
              />
            </Col>
          </Form.Group>
        }
        <CriterionQueryBuilder
          category={category}
          jsonTree={data.logic_tree}
          onQueryBuilderChange={changeTree}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onHide}>
          Annuler
        </Button>
        <Button variant="dark" onClick={save}>
          {data.uuid ? "Sauvegarder" : "Créer"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

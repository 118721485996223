import {makeStyles} from "@material-ui/core";
import React, {createContext, useState} from "react";
import {Toast} from "react-bootstrap";

export const SnackbarContext = createContext({});

// eslint-disable-next-line react/prop-types
export default function GlobalSnackbar ({children}) {
  const base = {
    show: false,
    text: "",
    type: "",
  };
  const [snackbar, setSnackbar] = useState({...base});

  const classes = useStyle({
    color: snackbar.type === "error" ? "#cc544b" : "#454d55",
    textColor: snackbar.type === "error" ? "#cc544b" : "white",
  });

  return (
    <SnackbarContext.Provider value={{set: setSnackbar}}>
      {children}
      <Toast
        delay={3000}
        autohide
        show={snackbar.show}
        onClose={() => setSnackbar({...base})}
        className={classes.snackbar}
      >
        <Toast.Body>{snackbar.text}</Toast.Body>
      </Toast>
    </SnackbarContext.Provider>
  );
}

const useStyle = makeStyles({
  snackbar: {
    position: "absolute",
    bottom: 36,
    left: 36,
    backgroundColor: ({color}) => color || "#454d55",
    color: ({textColor}) => textColor || "white",
  },
});
